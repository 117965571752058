@tailwind base;
@tailwind components;
@tailwind utilities;

.Toastify__toast-theme--light {
	background:rgb(17,24,39) !important;
}

.Toastify__progress-bar {
	background: #ccc !important;
}

html {
	overflow-y:auto !important;
}